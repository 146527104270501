.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: #f0f0f0;
    padding: 20px; 
  }
  
  .authenticated-content {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-between; 
  }
  
  .dashboard {
    flex: 1;
    width: 80%; 
    margin-right: 10px; 
  }
  
  .mission-chart {
    width: 20%; 
    margin-left: 10px; 
  }